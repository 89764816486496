<template>
  <v-container>
    <v-card
        elevation="2"
        class="mt-8 px-4"
        :loading="loading"
    >
      <div
        class="py-4"
      >
        <form>
            <v-text-field
                v-model="username"
                :error-messages="usernameErrors"
                label="Username"
                required
                @input="$v.username.$touch()"
                @blur="$v.username.$touch()"
            ></v-text-field>

            <v-text-field
                type="password"
                v-model="password"
                :error-messages="passwordErrors"
                label="Password"
                required
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
            ></v-text-field>

            <v-alert v-if="auth_error_message" type="error">
              {{auth_error_message}}
            </v-alert>


            <v-btn
                class="mr-4 mt-4"
                @click="submit()"
                :disabled="invalid"
            >
            Login
            </v-btn>
        </form>
      </div>
        
    </v-card>
    
  </v-container>

</template>

<script>
  
  import { validationMixin } from 'vuelidate'
  import { mapGetters } from 'vuex'
  import { required, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'Login',
    components: {

    },
    mixins: [validationMixin],

    validations: {
      password: { required, minLength: minLength(6)  },
      username: { required, minLength: minLength(3) }
    },

    data: () => ({
      username: '',
      password: '',
      loading: false
    }),

    computed: {
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password must be at least 6 characters long')
        !this.$v.password.required && errors.push('Password is required.')
        return errors
      },
      usernameErrors () {
        const errors = []
        if (!this.$v.username.$dirty) return errors
        !this.$v.username.minLength && errors.push('Username must be at least 6 characters long')
        !this.$v.username.required && errors.push('Username is required')
        return errors
      },
      invalid() {
        return this.$v.$invalid
      },
      ...mapGetters([
        'auth_error_message',
      ])
    },

    methods: {
      async submit () {
        this.$v.$touch()
        if (this.invalid) return;
        this.loading = true;
        await this.$store.dispatch('login', {username: this.username, password: this.password})
        this.loading = false;
      },
      clear () {
        this.$v.$reset()
        this.name = ''
        this.username = ''
        this.select = null
        this.checkbox = false
      },
    },
  }
</script>
